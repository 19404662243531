
































































































































































import {
  SfHeading,
  SfTable,
  SfCheckbox,
  SfButton,
  SfDivider,
  SfPrice,
  SfProperty,
  SfLink,
  SfImage,
} from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';

import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useImage } from '~/composables';
import useMakeOrder from '~/modules/checkout/composables/useMakeOrder';
import useCart from '~/modules/checkout/composables/useCart';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';
import { removeItem } from '~/helpers/asyncLocalStorage';
import { isPreviousStepValid } from '~/helpers/checkout/steps';
import type { BundleCartItem, ConfigurableCartItem, CartItemInterface } from '~/modules/GraphQL/types';
import ComponentA from '~/modules/checkout/components/VsfPaymentProvider.vue';

export default defineComponent({
  name: 'ReviewOrderAndPayment',
  components: {
    SfHeading,
    SfTable,
    SfCheckbox,
    SfButton,
    SfDivider,
    SfPrice,
    SfProperty,
    SfLink,
    SfImage,
    VsfPaymentProvider: () => import('~/modules/checkout/components/VsfPaymentProvider.vue'),
  },
  setup() {
    const order = ref(null);
    const { cart, load, setCart } = useCart();
    const { make, loading } = useMakeOrder();
    const { app } = useContext();
    const router = useRouter();
    const isPaymentReady = ref(false);
    const terms = ref(false);
    const getAttributes = (product: ConfigurableCartItem) => product.configurable_options || [];
    const getBundles = (product: BundleCartItem) => product.bundle_options?.map((b) => b.values).flat() || [];

    onMounted(async () => {
      const validStep = await isPreviousStepValid('billing');
      if (!validStep) {
        await router.push(app.localePath('/checkout/user-account'));
      }

      await load();
    });
    const VsfPaymentProviderRef = ref(null);
          
    const processOrder = async () => {
      const stripeStatus = await ComponentA.methods.myMethod();
      const stripeTrigger = await stripeStatus.value[0].setPaymentMethod();
      
      
     
      order.value = await make();
      setCart(null);
      app.$vsf.$magento.config.state.removeCartId();
      await load();
      await removeItem('checkout');
      // const stripeStatus = await VsfPaymentProviderRef.value.triggerStripe();
      
      // console.log({stripeTrigger});
      // Only return if stripe is the only payment method you wish to have
      // if (!stripeStatus || stripeStatus.message) return;
      // It's recommended displaying any errors to the user, in this case it's adviced to use SfNotification component
      const thankYouRoute = app.localeRoute({
        name: 'thank-you',
        query: {
          order: order.value.order.order_number,
        },
      });
      await router.push(thankYouRoute);
    };

    const discounts = computed(() => cartGetters.getDiscounts(cart.value));
    const hasDiscounts = computed(() => discounts.value.length > 0);
    const discountsAmount = computed(
      () => -1 * discounts.value.reduce((a, el) => el.value + a, 0),
    );

    const { getMagentoImage, imageSizes } = useImage();
    const getRowTotal = (product: CartItemInterface) => cartGetters.getItemPrice(product).regular - cartGetters.getItemPrice(product).special;
    return {
      cart,
      cartGetters,
      discounts,
      hasDiscounts,
      discountsAmount,
      getShippingMethodPrice,
      isPaymentReady,
      loading,
      processOrder,
      products: computed(() => cartGetters.getItems(cart.value)),
      selectedShippingMethod: computed(() => cartGetters.getSelectedShippingMethod(cart.value)),
      tableHeaders: ['Description', 'Quantity', 'Amount'],
      terms,
      totals: computed(() => cartGetters.getTotals(cart.value)),
      getAttributes,
      getBundles,
      getMagentoImage,
      imageSizes,
      getRowTotal,
    };
  },
});
