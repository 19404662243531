























import { SfRadio } from '@storefront-ui/vue';
import { ref, onMounted, defineComponent } from '@nuxtjs/composition-api';
import usePaymentProvider from '~/modules/checkout/composables/usePaymentProvider';
import type { AvailablePaymentMethod } from '~/modules/GraphQL/types';
import { Stripe } from '@headlesscommerce/vsf-magento-stripe';

let ajay:any = '';

export default defineComponent({
  name: 'VsfPaymentProvider',
  components: {
    SfRadio,
    Stripe
  },
  methods: {
    myMethod() {
      // Your method logic goes here
      console.log('Method triggered!');
      console.log(ajay);
      return ajay;
      // return StripeRef.value[0].setPaymentMethod();
    }
  },
  emits: ['status'],
  setup(_props, { emit }) {
    let StripeRef = ref(null);
    let paymentStatus = ref(null);
    let triggerStripe = ref(null);
    const paymentMethods = ref<AvailablePaymentMethod[]>([]);
    const { load, save } = usePaymentProvider();
    const selectedPaymentMethodCode = ref<string | null>(null);
    const stripeKey = process.env.STRIPE_API_KEY;

    onMounted(async () => {
      paymentMethods.value = await load();
    });

    const definePaymentMethods = async (paymentMethodCode: string) => {
      paymentMethods.value = await save({
        paymentMethod: {
          code: paymentMethodCode,
        },
      });

      selectedPaymentMethodCode.value = paymentMethodCode;
      console.log(selectedPaymentMethodCode);

      if (paymentMethodCode !== 'stripe_payments') {
        // Avoid emitting just yet, see paymentStatus function
        emit('status', paymentMethodCode);
      }
      ajay = StripeRef;
      // Validate and initiate stripe setPaymentMethod function
      triggerStripe.value = async() => {
        if (selectedPaymentMethodCode.value == 'stripe_payments') {
          console.log('capture');
          return StripeRef.value[0].setPaymentMethod();
        } else {
          console.log('not capture');
          return;
        }
      }
      paymentStatus.value = () => {
        emit('status', 'true');
      };
    };

    return {
      paymentMethods,
      selectedPaymentMethodCode,
      definePaymentMethods,
      paymentStatus,
      triggerStripe,
      StripeRef,
      stripeKey,
    };
  },
});
